// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/trash_bin.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-edit-form-container[data-v-7e873783]{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;gap:2%;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-bottom:40px}.add-edit-form-container .input-width-set[data-v-7e873783]{width:510px}@media screen and (max-width:1480px){.add-edit-form-container .input-width-set[data-v-7e873783]{width:80%}}.add-edit-form-container .map[data-v-7e873783]{width:40%;height:700px}.add-edit-form-container .form[data-v-7e873783]{width:42%}.add-edit-form-container .form .buttons[data-v-7e873783]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-left:220px;gap:40px}.add-edit-form-container .form .field[data-v-7e873783]{gap:0;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;display:-webkit-box;display:-ms-flexbox;display:flex}.add-edit-form-container .form .field-with-button[data-v-7e873783]{gap:10px}.add-edit-form-container .form p[data-v-7e873783]{margin-right:10px}.add-edit-form-container .form .outages-button[data-v-7e873783]{background-color:transparent;border:none;width:24px;height:24px}.add-edit-form-container .form .outages-button-trash[data-v-7e873783]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
