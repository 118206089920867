<template>
    <div>
        <EonIconButton v-if="iconButton" :class="iconColor" :withSvg="iconButtonWithSvg" :icon="icon" @click="open"/>
        <EonButton v-else :label="label" :icon="icon" :extension="extension" @click="open"/>
        <PrimeDialog class="delete-or-refresh-dialog" :id="name" v-model:visible="display" :draggable="false"
                     :modal="true" :style="'width: ' + dialogWidth + 'px;'" :closeable="false">
            <slot>
                <h2>Na pewno?</h2>
                <Toolbar>
                    <template #start>
                        <EonButton label="Tak" @click="confirmClose"/>
                    </template>
                    <template #end>
                        <EonButton label="Anuluj" type="button" @click="close"/>
                    </template>
                </Toolbar>
            </slot>
        </PrimeDialog>
    </div>
</template>

<script>

    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import EonIconButton from "@/components/form/EonIconButton";
    import Toolbar from "primevue/toolbar";
    import {
        deleteOrRefreshUserUsingPOST as deleteOrRefreshUser,
        deletePlannedOutageReportUsingDELETE as deletePlannedReport,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EonDeleteOrRefreshPopup",
        props: {
            name: {
                type: String,
            },
            reload: {
                type: Function,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
            },
            iconButton: {
                type: Boolean,
                default: true,
            },
            iconButtonWithSvg: {
                type: Boolean,
                default: false,
            },
            iconColor: {
                type: String,
            },
            extension: {
                type: String,
                default: "0",
            },
            dialogWidth: {
                type: String,
            },
            entityId: {
                default: null,
            },
            entityType: {
                default: null,
            },
        },

        data() {
            return {
                value: null,
                display: false,
            };
        },

        methods: {
            async confirmClose() {
                switch (this.entityType) {
                    case "user":
                        await deleteOrRefreshUser({id: this.entityId});
                        break;
                    case "planned":
                        await deletePlannedReport({id: this.entityId});
                        break;
                    default:
                        return;
                }
                this.close();
                this.reload();
            },
            open() {
                this.display = true;
            },
            close() {
                this.display = false;
            },
        },

        components: {
            EonIconButton, PrimeDialog, EonButton, Toolbar,
        },
    };
</script>

<style lang="less">
@import "../../assets/theme/variable.less";

.delete-or-refresh-dialog {
    h2 {
        text-align: center;
    }
}

.delete-or-refresh-dialog.p-dialog-mask.p-component-overlay {
    background-color: transparent;
    backdrop-filter: none;
}

.delete-or-refresh-dialog.p-dialog {
    border-radius: 4px;
    box-shadow: 0 4px 4px 0  rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid @color-turquoise;
    .p-dialog-header {
        display: none;
    }
    .p-dialog-content {
        background: @color-white;
        color: @color-black;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
    .p-dialog-footer {
        border-top: 0 none;
        background: @color-white;
        color: @color-black;
        padding: 1rem 1.5rem;
        text-align: right;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }
    }
}
</style>
