<!-- Jeśli w props przekażemy wartość icon, np. "pi pi-search",
to pojawi się po prawej stronie pola podana w propsie ikonka -->
<template>
    <div :class="['eon-inputtext-field', color, computedClass]">
        <span :class="['p-float-label', {'p-input-icon-right': computedIcon}, color]">
            <i :class="[{'eon-inputtext-icon-on': computedIcon}, {'eon-inputtext-icon-off': !computedIcon}, icon]" />
            <InputText :class="color" :id="name" type="text" v-model="internalValue" @keyup.enter="takeAddress"
                       @input="handleChange" @blur="handleBlur" :style="'width: ' + width + 'px;'"
                       :placeholder="placeholder"
                       :maxlength="maxlength" />
            <PrimeButton v-if="addButton" icon="pi pi-plus" @click="takeAddress"
                         class="add-button" />
            <label v-show="showLabel" :for="name">{{ label }}</label>
        </span>
        <span v-show="errorMessage" class="p-invalid">
            <i class="pi pi-exclamation-triangle" /> {{ errorMessage }}
        </span>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext";
    import {useField} from "vee-validate";
    import PrimeButton from "primevue/button";

    export default {
        name: "EonInputText",
        props: {
            googleMapsApi: null,
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            maxlength: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
            },
            color: {
                type: String,
            },
            addButton: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
            placeholder: {
                type: String,
                default: "",
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },

        emits: ["update:modelValue", "takeAddress"],

        computed: {
            computedIcon() {
                return this.icon != null;
            },

            computedClass() {
                return this.errorMessage ? "invalid-input" : "";
            },

            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            takeAddress() {
                this.$emit("takeAddress", this.inputValue);
                this.inputValue = "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {InputText, PrimeButton},
    };
</script>

<style lang="less">

@import "../assets/less/inputtext.less";
@import "../assets/less/eon-inputtext.less";
@import "../assets/less/float-label.less";
@import "../../assets/theme/variable.less";

.add-button {
    position: absolute;
    height: 100%;
    right: 12px;
    border: none;
    background: none;
    color: @color-red;
    outline: none;
}

</style>
