<template>
    <h1>Użytkownicy</h1>
    <UsersSearchCriteria ref="userSearchCriteria" v-model="searchCriteria" @update:modelValue="search"/>
    <UsersTable ref="userTable" :search-criteria="searchCriteria"/>
</template>

<script>
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import UsersSearchCriteria from "./components/UsersSearchCriteria";
    import UsersTable from "./components/UsersTable";

    export default {
        components: {
            UsersSearchCriteria,
            UsersTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.userTable.search();
            },

            createdBy() {
                this.$router.push({name: "userCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    id: null,
                    systemRole: null,
                    area: null,
                    deleted: null,
                    firstName: "",
                    lastName: "",
                    email: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style lang="less" scoped>
    h1 {
        margin-bottom: 0;
    }
</style>
