<template>
    <Toast />
    <EonCookies class="cookies" :buttonLabel="($t('message.cookies.accept'))">

    </EonCookies>
    <div class="main-field">
        <div class="eon-login-field">
            <VeeForm @submit="login">
                <h2>{{ $t('message.login.eonTitle') }}</h2>
                <p>{{ $t('message.login.eonDescription') }}</p>
                <div class="login-inputs" @keyup.enter="login">
                    <EonInputText rules="required|email" color="turquoise" name="login" v-model="loginForm.login"
                                  :label="$t('message.login.eonLogin')"/>
                    <EonPassword rules="required" color="turquoise" name="password"
                                 v-model="loginForm.password"
                                 :label="$t('message.login.password')"/>

                </div>
                <div class="login-actions">
                    <EonButton class="inverted turquoise" :label="$t('message.login.action.login')"
                               @click="login" type="submit" />
                    <EonHref href="/remind" :label="$t('message.login.action.eonRemindPassword')"/>
                </div>
            </VeeForm>
        </div>

        <div class="eon-register-field">
            <h2> {{ $t('message.register.title') }} </h2>
            <p> {{ $t('message.register.message') }}</p>
            <div class="button">
                <EonOutlinedButton class="inverted bordeaux" :label="($t('message.register.apply'))"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {loginUsingPOST as loginRequest} from "@/swagger/vue-api-client";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonPassword from "@/components/form/EonPassword";
    import EonInputText from "@/components/form/EonInputText";
    import EonCookies from "@/components/form/EonCookies";
    import Toast from "primevue/toast";
    import EonHref from "@/components/form/EonHref";
    import {Form as VeeForm} from "vee-validate";
    import {StatusCodes} from "@/utils/StatusCodes";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "EonLoginView",
        components: {
            EonButton,
            EonPassword,
            EonInputText,
            EonCookies,
            Toast,
            VeeForm,
            EonOutlinedButton,
            EonHref,
        },

        data() {
            return {
                loginForm: {
                    login: "",
                    password: "",
                },
                isBlocked: false,
                badCredentials: false,
                Role: SystemRole,
            };
        },
        methods: {
            toast() {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "error", summary: "Niepoprawne dane logowania", life: 5000, closable: false,
                });
            },
            login() {
                loginRequest({authenticationRequest: this.loginForm})
                    .then((response) => {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("userId", response.data.userId);
                        localStorage.setItem("role", response.data.role);
                        localStorage.setItem("fullName", response.data.fullName);
                        localStorage.setItem("area", response.data.area);
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem("phone", response.data.phone);

                        if (this.Role.isAdmin()) {
                            this.$router.push({name: "usersAdmin"});
                        } else if (this.Role.isDispatcher()) {
                            this.$router.push({name: "plannedMaster"});
                        } else if (this.Role.isAccepter()) {
                            this.$router.push({name: "plannedAccept"});
                        } else if (this.Role.isCallCenter()) {
                            this.$router.push({name: "plannedCallCenter"});
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        switch (error.response.status) {
                            case StatusCodes.SERVICE_UNAVAILABLE:
                                this.isBlocked = true;
                                this.badCredentials = false;
                                break;
                            case StatusCodes.FORBIDDEN:
                                this.badCredentials = true;
                                this.toast();
                                break;
                            default:
                        }
                    });
            },
        },
    };
</script>

<style lang="less">
@import "assets/less/eon-login.less";
@import "../components/assets/less/toast.less";

.button {
    display: flex;
    justify-content: center;
}

</style>
