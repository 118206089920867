<template>
    <div>
        <Toast />
        <DataTable ref="plannedOutageReportMasterTable" class="outages-table" :value="plannedOutageReports"
                   :lazy="true" :paginator="true" :rowClass="rowClass"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort v-model:selection="selectedOutages"
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Elementy na stronie" responsiveLayout="scroll">
            <Column selectionMode="multiple" style="width: 40px" />
            <Column header="Adres">
                <template #body="slotProps">
                    <ul v-if="slotProps.data.addresses.length <= 10" class="outage-address-list">
                        <li v-for="address in slotProps.data.addresses" :key="address.address">
                            <b>{{ address.nonFormattedAddress }}</b> - {{ address.street }}
                            {{ address.wesolaDistrict ? "(W)" : "" }}
                            {{ address.streetNumber }}{{ ", " + address.postcode }}
                        </li>
                    </ul>
                    <ul v-else class="outage-address-list">
                        <li v-for="index in 10" :key="index">
                            <b>{{ slotProps.data.addresses[index].nonFormattedAddress }}</b> -
                            {{ slotProps.data.addresses[index].street }}
                            {{ slotProps.data.addresses[index].wesolaDistrict ? "(W)" : "" }}
                            {{ slotProps.data.addresses[index].streetNumber }}
                            {{ ", " + slotProps.data.addresses[index].postcode }}
                        </li>
                        <b>...</b>
                    </ul>
                </template>
            </Column>
            <Column field="outageStart" header="Czas rozpoczęcia" :sortable="true" style="width: 140px;" />
            <Column field="outageEnd" header="Czas zakończenia" :sortable="true" style="width: 140px;" />
            <Column field="voltageLevel" header="Napięcie" :sortable="true" style="text-align: center;">
                <template #body="slotProps">
                    {{ slotProps.data.voltageLevel === "LV" ? "nN" : "" }}
                    {{ slotProps.data.voltageLevel === "MV" ? "SN" : "" }}
                    {{ slotProps.data.voltageLevel === "HV" ? "WN" : "" }}
                </template>
            </Column>
            <Column field="taskNumber" header="Nr SAP/CallNet" :sortable="true" style="width: 140px;" />
            <Column field="acceptStatus" header="Status akceptacji" :sortable="true" style="width: 160px;">
                <template #body="slotProps">
                    <span v-if="slotProps.data.acceptStatus === 'ACCEPTED'" style="color: green;">
                        Zaakceptowane
                    </span>
                    <span v-else-if="slotProps.data.acceptStatus === 'REJECTED'" style="color: red;">
                        Odrzucone
                    </span>
                    <span v-else>
                        Do akceptacji
                    </span>
                </template>
            </Column>
            <Column style="width: 120px">
                <template #body="slotProps">
                    <div class="button-column">
                        <router-link :to="{name: 'plannedOutageReportMasterShow', params: {'id': slotProps.data.id}}">
                            <PrimeButton class="outages-button outages-button-show" />
                        </router-link>
                        <router-link :to="{name: 'plannedOutageReportEditByMaster', params: {'id': slotProps.data.id}}">
                            <PrimeButton class="outages-button outages-button-edit" />
                        </router-link>
                        <EonDeleteOrRefreshPopup class="outages-button outages-button-trash"
                                                 :entity-id="slotProps.data.id" :reload="search"
                                                 entityType="planned" />
                        <PrimeButton class="outages-button outages-button-print"
                                     @click="downloadAnnouncement(slotProps.data)" />
                    </div>
                </template>
            </Column>
            <template #loading>
                Ładowanie planowanych wyłączeń. Proszę czekać.
            </template>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import PrimeButton from "primevue/button";
    import EonDeleteOrRefreshPopup from "@/components/form/EonDeleteOrRefreshPopup";
    import Toast from "primevue/toast";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {TimeUtils} from "@/utils/TimeUtils";
    // import {SystemRole} from "@/utils/SystemRole";
    import {
        deleteManyPlannedOutageReportUsingPOST as deleteSelected,
        dispatcherSearchPlannedOutageReportUsingPOST as searchPlannedOutageReport,
        dispatcherSearchPlannedOutageReportCountUsingPOST as searchPlannedOutageReportCount,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "PlannedOutageReportMasterTable",

        components: {
            DataTable,
            Column,
            EonCheckbox,
            PrimeButton,
            Toast,
            EonDeleteOrRefreshPopup,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                plannedOutageReports: [],
                selectedOutages: null,
                acceptStatus: false,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            downloadAnnouncement(data) {
                // eslint-disable-next-line max-len
                FileUtils.downloadFileUsingGET(`Planowane wyłączenie (${data.outageStart} - ${data.outageEnd}).docx`, `/api/planned-outage/announcement/${data.id}`);
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchPlannedOutageReport({searchCriteria: TimeUtils.adjustRequestBody(this.searchCriteria)})
                    .then((response) => {
                        this.plannedOutageReports = response.data;
                        this.plannedOutageReports.forEach((report) => {
                            report.outageStart = new Date(report.outageStart)
                                .toLocaleString("pl-PL", TimeUtils.options);
                            report.outageEnd = new Date(report.outageEnd)
                                .toLocaleString("pl-PL", TimeUtils.options);
                            report.outageAnnouncement = new Date(report.outageAnnouncement)
                                .toLocaleString("pl-PL", TimeUtils.options);
                        });
                        this.loading = false;
                    });

                SearchCriteria.updateUrl(this);
            },

            rowClass(rowData) {
                return rowData.deleted ? "deleted-row" : null;
            },

            updateTotalRecords() {
                searchPlannedOutageReportCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            async deleteSelectedOutages() {
                if (this.selectedOutages != null && this.selectedOutages.length > 0) {
                    const selectedIds = this.selectedOutages.map((outage) => outage.id);
                    deleteSelected({ids: selectedIds});
                    this.$toast.removeAllGroups();
                    this.$toast.add({
                        severity: "success",
                        summary: "Pomyślnie usunięto zaznaczone wyłączenia",
                        life: 2500,
                    });
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise((r) => setTimeout(r, 300));
                    this.search();
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Nie zaznaczono żadnych wyłączeń",
                        life: 2500,
                    });
                }
            },
        },
    };
</script>

<style lang="less">
@import "../../../components/assets/less/datatable.less";
@import "../../assets/less/eon-table.less";
.deleted-row {
    background-color: @tint-grey-ultra-ultralight !important;
}
.outages-table .no-cursor .p-checkbox-disabled {
    cursor: default;
}

.outages-table {
    .p-selection-column, .p-column-header-content {
      .p-checkbox-box.p-component {
        &:not(.p-highlight) {
          display: flex;
        }
      }
    }
}
</style>

<style lang="less" scoped>
@import "../../../components/assets/less/datatable.less";
@import "../../assets/less/eon-table.less";
.button-column {
    display: flex;
    justify-content: center;
    gap: 4px;
}
.outages-button {
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    &-show {
        background-image: url("@/assets/show.svg");
    }
    &-edit {
        background-image: url("@/assets/edit_no_line.svg");
    }
    &-trash {
        background-image: url("@/assets/trash_bin.svg");
    }
    &-print {
        background-image: url("@/assets/printer.svg");
    }
}
.outage-address-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
</style>
