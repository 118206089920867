<template>
    <div :class="computedClass" v-if="loading">
        <EonSublist v-for="item in items" :key="item" :label="item.name" :FAQList="FAQList"
                    @area-clicked="handleClick(item.id)" :item-id="item.id" :description="item.description"
                    :arrowIcons="arrowIcons"/>
        <p v-if="!items.length"> {{ this.FAQList ? "Brak pytań" : "Brak obszarów - dodaj je poniżej" }} </p>
    </div>
</template>

<!-- eslint-disable max-len -->
<script>

    import EonSublist from "@/components/form/EonSublist";
    import {
        getAreasUsingGET as getAreas,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EonList",

        props: {
            FAQList: {
                default: false,
            },
            arrowIcons: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            searchItems() {
                if (this.FAQList) {
                    this.items = this.FAQItems;
                    this.loading = true;
                } else {
                    getAreas().then((response) => {
                        this.items = response.data;
                        this.loading = true;
                    });
                }
            },
            handleClick(areaId) {
                this.$emit("area-selected", areaId);
            },
        },

        computed: {
            computedClass() {
                return this.FAQList ? "faq-list" : "eon-list";
            },
        },

        data() {
            return {
                // https://störungsauskunft.de/stromausfall/infos
                FAQItems: [
                    {
                        name: "1. Dlaczego zdarzają się wyłączenia prądu?",
                        id: 1,
                        description: "<ul><b>Przyczyny braku energii elektrycznej w Twoim domu mogą być następujące:</b><br><br>"
                            + "<ul><li>awaria sieci energetycznej, np. uszkodzony kabel, zerwana sieć napowietrzna (burza, przewrócone drzewo) lub kradzież sieci;</li>"
                            + "<li>awaria instalacji domowej lub wewnętrznej linii zasilającej w budynkach wielolokalowych;</li>"
                            + "<li>modernizacja sieci, np. przyłączenie nowo wybudowanej sieci energetycznej, konserwacja stacji transformatorowej - są to prace planowane przez nas. Standardowym "
                            + "sposobem informowania o nich są komunikaty na naszym <a href=\"https://awaria.stoen.pl/public/planned\"><b>Portalu</b></a> oraz ogłoszenia zamieszczane z 5-"
                            + "dniowym wyprzedzeniem w rejonie objętym planowym wyłączeniem;</li>"
                            + "<li>wstrzymanie dostaw z tytułu braku płatności za energię elektryczną.</li></ul></ul>",
                    },
                    {
                        name: "2. Co zrobić, gdy nie ma prądu ?",
                        id: 1,
                        description: "<ul><b>Lokal mieszkalny/użytkowy</b><br><br>"
                            + "Gdy nie ma prądu w Twoim mieszkaniu, sprawdź bezpieczniki (korki) w tablicy "
                            + "rozdzielczej, zabezpieczenia przedlicznikowe na klatce schodowej oraz czy u "
                            + "sąsiadów i na klatce schodowej jest światło. <br><br>"
                            + "Jeśli zabezpieczenia są włączone oraz u sąsiadów i na klatce schodowej jest światło, "
                            + "to znaczy, że naprawa przyczyn braku energii w Twoim lokalu nie jest zadaniem dla "
                            + "pogotowia energetycznego Stoen Operator. Usterka nastąpiła najprawdopodobniej w "
                            + "instalacji wewnętrznej. W takiej sytuacji prosimy o indywidualny kontakt z elektrykiem "
                            + "lub administratorem/właścicielem budynku.<br><br>"
                            + "<b>Zabudowa jednorodzinna</b><br><br>"
                            + "Gdy nie ma prądu w Twoim domu, sprawdź bezpieczniki (korki) w tablicy rozdzielczej, "
                            + "zabezpieczenia przedlicznikowe na zewnątrz budynku oraz czy w sąsiednich "
                            + "budynkach jest światło.<br><br>"
                            + "Jeśli zabezpieczenia są włączone oraz u sąsiadów i na ulicy nie ma światła, to "
                            + "najprawdopodobniej jest to planowane wyłączenie lub awaria.<br><br>"
                            + "<b>Aby zgłosić awarię, zadzwoń na pogotowie energetyczne Stoen Operator pod numer 22 821 "
                            + "31 31 lub zrób to za pomocą formularza na naszym</b> <a href=\"https://awaria.stoen.pl/public\"><b>Portalu</b></a>.</ul>",
                    },
                    {
                        name: "3. Czym spowodowane są przerwy planowane?",
                        id: 1,
                        description: "<ul>Realizacje prac, takich jak przyłączenia do sieci nowych odbiorców lub prace eksploatacyjne,"
                            + " mogą wiązać się z przerwami w dostawie energii. Są to najczęściej przerwy dłuższe i"
                            + " zaplanowane z wyprzedzeniem. O takich sytuacjach informujemy odbiorców przed"
                            + " rozpoczęciem prac. W tym celu wywieszamy papierowe ogłoszenia w okolicy, a także"
                            + " zamieszczamy komunikat na naszym <a href=\"https://awaria.stoen.pl/public/planned\"><b>Portalu</b></a>.</ul>",
                    },
                    {
                        name: "4. Kiedy występują awarie sieci?",
                        id: 1,
                        description: "<ul>Najczęściej awarie spowodowane są niekorzystnymi warunkami atmosferycznymi, takimi jak"
                            + " silne wiatry, burze, intensywne opady śniegu i szadź, oraz uszkodzeniami związanymi z"
                            + " oddziaływaniem ludzkim, np. uszkodzenie linii, prace budowlane, kradzież przewodów z sieci.</ul>",
                    },
                    {
                        name: "5. Dlaczego nie informujecie o awariach?",
                        id: 1,
                        description: "<ul>Awarie są zdarzeniem nagłym, nieplanowanym, dlatego nie jesteśmy w stanie ich przewidzieć"
                            + " (zwłaszcza przerw w zasilaniu spowodowanych np. burzą lub przewróconym drzewem).<br><br>"
                            + " W przypadku wystąpienia awarii rozległych, informacji o nich udzielamy w formie komunikatu"
                            + " głosowego na naszej infolinii (22) 821 31 31 oraz na naszym <a href=\"https://awaria.stoen.pl/public\"><b>Portalu</b></a>.<br><br>"
                            + " W momencie, w którym rozpoczynamy naprawę, możemy jedynie podać przewidywany czas"
                            + " przywrócenia zasilania. Na rzeczywisty czas przywrócenia zasilania może wpłynąć szereg"
                            + " różnych okoliczności.</ul>",
                    },
                    {
                        name: "6. Jak długo może trwać dopuszczalna przerwa w dostawie energii elektrycznej?",
                        description: "<ul><p>Maksymalny dopuszczalny czas przerw w dostawach energii jest uregulowany przepisami"
                            + " prawa<sup>1</sup>. W przypadku obiektów zakwalifikowanych do grupy przyłączeniowej IV<sup>2</sup>-V<sup>3</sup>"
                            + " jednorazowe przerwy mogą trwać nie dłużej niż:</p>"
                            + "<ul><li>16 godzin dla przerwy planowanej,</li>"
                            + "<li>24 godziny dla przerwy nieplanowanej.</li></ul>"
                            + " <p>W ciągu roku dopuszczalny łączny czas trwania przerw jednorazowych długich<sup>4</sup> i bardzo"
                            + " długich<sup>5</sup> w dostawach energii elektrycznej wynosi:</p>"
                            + "<ul><li>35 godzin dla przerw planowanych,</li>"
                            + "<li>48 godzin dla przerw nieplanowanych.</li></ul>"
                            + " <p>W przypadku przekroczenia dopuszczalnego czasu trwania przerw w dostawach energii "
                            + "elektrycznej przysługuje Ci bonifikata za niedostarczoną ilość energii elektrycznej. W tym celu "
                            + "możesz złożyć wniosek (więcej informacji znajdziesz w odpowiedzi na pytanie nr 11).</p></ul>"
                            + "<ol style='font-size: small'>"
                            + "<li>Rozporządzenie Ministra Gospodarki z dnia 4 maja 2007 r. w sprawie szczegółowych warunków funkcjonowania systemu elektroenergetycznego.</li>"
                            + "<li>Grupa IV - podmioty przyłączane bezpośrednio do sieci rozdzielczej, o napięciu znamionowym nie "
                            + "wyższym niż 1 kV oraz mocy przyłączeniowej większej od 40 kW lub prądzie znamionowym "
                            + "zabezpieczenia przedlicznikowego w torze prądowym większym od 63 A.</li>"
                            + "<li>Grupa V - podmioty przyłączane bezpośrednio do sieci rozdzielczej, o napięciu znamionowym nie "
                            + "wyższym niż 1 kV oraz mocy przyłączeniowej nie większej niż 40 kW i prądzie znamionowym "
                            + "zabezpieczenia przedlicznikowego nie większym niż 63 A.</li>"
                            + "<li>Przerwy długie - trwające nie krócej niż 3 minuty i nie dłużej niż 12 godzin.</li>"
                            + "<li>Przerwy bardzo długie - trwające nie krócej niż 12 godzin i nie dłużej niż 24 godziny.</li>"
                            + "</ol>",
                        id: 1,
                    },
                    {
                        name: "7. Jakie są przyczyny chwilowego zaniku napięcia?",
                        id: 1,
                        description: "<ul>Krótkie przerwy w dostawach energii elektrycznej to tak zwane przemijające i krótkie przerwy. "
                            + "Trwają nie dłużej niż 3 minuty i są naturalne w sieciach elektroenergetycznych. Powoduje je "
                            + "działanie urządzeń automatyki sieciowej<sup>1</sup>, która jednocześnie chroni odbiorców przed "
                            + "dłuższymi przerwami.</ul>"
                            + "<ol style='font-size: small'>"
                            + "<li>Odpowiadają one za automatyczne przełączenia lub podział sieci w przypadku braku zasilania na danym jej odcinku.</li>"
                            + "</ol>",
                    },
                    {
                        name: "8. Gdzie zgłaszać uszkodzenia związane z siecią energetyczną?",
                        id: 1,
                        description: "<ul><b>W przypadku gdy:</b><br><br>"
                            + " <ul><li>urządzenia elektryczne pozbawione są zabezpieczenia (np. otwarte drzwi do stacji"
                            + " transformatorowej lub otwarte skrzynki), </li>"
                            + " <li>zerwana jest sieć energetyczna, uszkodzony słup,</li></ul><br>"
                            + " zadzwoń na Pogotowie energetyczne Stoen Operator pod numer: 22 821 31 31."
                            + "<br><br> W sytuacji zagrożenia życia i mienia należy powiadomić również służby ratownicze pod numerem 112.</ul>",
                    },
                    {
                        name: "9. Jak pracuje Pogotowie energetyczne Stoen Operator?",
                        id: 1,
                        description: "<ul>Pogotowie energetyczne Stoen Operator (tel. 22 821 31 31) pracuje 24h na dobę przez"
                            + " 7 dni w tygodniu. Jego zadaniem jest naprawa uszkodzonych elementów w obszarze swojej"
                            + " sieci dystrybucyjnej. W pierwszej kolejności usuwane są te awarie, które stanowią zagrożenie "
                            + "dla życia bądź zdrowia, np. przewrócone/złamane słupy, leżące na ziemi kable lub"
                            + " zerwane przewody przesyłowe.</ul>",
                    },
                    {
                        name: "10. Kiedy wezwanie Pogotowia energetycznego Stoen Operator jest niezasadne?",
                        id: 1,
                        description: "<ul>Wezwanie Pogotowia energetycznego oraz jego interwencja jest niezasadna, jeśli awaria nie "
                            + "dotyczy sieci dystrybucyjnej Stoen Operator. Wewnętrzne instalacje w budynku, mieszkaniu, "
                            + "czy domu nie są własnością dystrybutora energii elektrycznej.</ul>",
                    },
                    {
                        name: "11. Kiedy możesz wystąpić o bonifikatę za przerwy w dostawie energii elektrycznej oraz w jaki sposób?",
                        id: 1,
                        description: "<ul><b>Kwalifikacja do bonifikaty</b><br><br>"
                            + "Aby wystąpić o przyznanie bonifikaty najpierw:<br><br>"
                            + "<ul><li>sprawdź, czy przerwy w dostawie energii były planowane – możesz to zrobić na"
                            + " naszym <a href=\"https://awaria.stoen.pl/public/planned\"><b>Portalu</b></a>,</li>"
                            + " <li>sprawdź, czy przerwy w dostawie energii przekroczyły dopuszczone przepisami"
                            + " limity (więcej informacji znajdziesz w odpowiedzi na pytanie nr 6).</li></ul><br>"
                            + "Czas trwania przerwy nieplanowanej jest liczony od momentu uzyskania przez Stoen"
                            + " Operator informacji o jej wystąpieniu do czasu wznowienia dostarczania energii elektrycznej.<br><br>"
                            + " W przypadku kiedy przerwy w dostawach energii były dłuższe niż w przewidzianych limitach,"
                            + " możesz złożyć wniosek o udzielenie bonifikaty. Przyznana bonifikata zostanie uwzględniona w rozliczeniu za najbliższy okres rozliczeniowy<sup>1</sup>.<br><br>"
                            + "<b>Co powinien zawierać wniosek o bonifikatę?</b><br><br>"
                            + "We wniosku:<br><br>"
                            + "<ul><li>napisz, że ubiegasz się o bonifikatę z tytułu przekroczenia dopuszczalnego czasu "
                            + "trwania przerwy w dostarczaniu energii elektrycznej,</li>"
                            + " <li>wskaż miejsce i czas przerwy, której dotyczy wniosek,</li>"
                            + "<li>podaj swoje dane (imię, nazwisko/nazwa firmy), numer partnera handlowego oraz numer konta umowy.</li></ul><br><br>"
                            + "<b>Uwaga! </b>Zgłoszenie informacji o awarii nie jest równoznaczne ze złożeniem wniosku o "
                            + "bonifikatę.<br><br>"
                            + ""
                            + "<b>Wniosek o bonifikatę możesz złożyć na następujące sposoby:</b><br><br>"
                            + "<ul><li>pisemnie na nasz adres korespondencyjny:<br> Stoen Operator Sp. z o.o.<br> ul. Nieświeska 52<br> 03-"
                            + "867 Warszawa</li>"
                            + "<li>osobiście w Biurze Obsługi Klientów – Dystrybucja: <br>ul. Rudzka 18<br> 01-689 "
                            + "Warszawa</li>"
                            + "<li>elektronicznie, w postaci skanu pisma lub wiadomość e-mail na adres: <br>"
                            + "operator@stoen.pl</li></ul></ul><br>"
                            + "<ol style='font-size: small'><li>Zgodnie z par. 45 ROZPORZĄDZENIA MINISTRA KLIMATU I ŚRODOWISKA z dnia 29 listopada 2022 r. w sprawie "
                            + "sposobu kształtowania i kalkulacji taryf oraz sposobu rozliczeń w obrocie energią: Przedsiębiorstwo energetyczne, z "
                            + "którym odbiorca zawarł umowę przesyłania lub dystrybucji energii elektrycznej albo umowę kompleksową, "
                            + "uwzględnia udzieloną bonifikatę w rozliczeniach za najbliższy okres rozliczeniowy.</li></ol>",
                    },
                ],
                items: [],
                loading: false,
            };
        },

        beforeMount() {
            this.searchItems();
        },

        components: {EonSublist},
    };
</script>

<style lang="less">
@import "../assets/less/list.less";
a, a:visited, a:hover, a:active {
    color: #0F738A;
}
</style>
