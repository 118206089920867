<template>
    <PrimeButton v-if="withSvg" iconPos="right" class="eon-icon-button" type="button" :width="width" :color="color">
        <span class="button-inside">
            <span class="button-icon" v-if="icon" :class="icon"></span>
            <span>
                <!-- eslint-disable max-len -->
                <svg v-if="small" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82619 1.158C4.66946 1.37763 3.60561 1.94033 2.77306 2.77287C1.94051 3.60542 1.37782 4.66927 1.15819 5.826C-0.386063 13.9418 -0.386063 22.0582 1.15819 30.174C1.37782 31.3307 1.94051 32.3946 2.77306 33.2271C3.60561 34.0597 4.66946 34.6224 5.82619 34.842C9.8388 35.6096 13.9148 35.9973 18.0002 36C22.0856 35.9973 26.1616 35.6096 30.1742 34.842C31.3309 34.6224 32.3948 34.0597 33.2273 33.2271C34.0599 32.3946 34.6226 31.3307 34.8422 30.174C36.3864 22.0582 36.3864 13.9418 34.8422 5.826C34.6226 4.66927 34.0599 3.60542 33.2273 2.77287C32.3948 1.94033 31.3309 1.37763 30.1742 1.158C26.1616 0.390337 22.0856 0.00262201 18.0002 0C13.9148 0.00262201 9.83879 0.390337 5.82619 1.158Z" fill="#EA1B0A"/>
                </svg>
                <svg v-else-if="medium" width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82619 1.158C4.66946 1.37763 3.60561 1.94033 2.77306 2.77287C1.94051 3.60542 1.37782 4.66927 1.15819 5.826C-0.386063 13.9418 -0.386063 22.0582 1.15819 30.174C1.37782 31.3307 1.94051 32.3946 2.77306 33.2271C3.60561 34.0597 4.66946 34.6224 5.82619 34.842C9.8388 35.6096 13.9148 35.9973 18.0002 36C22.0856 35.9973 26.1616 35.6096 30.1742 34.842C31.3309 34.6224 32.3948 34.0597 33.2273 33.2271C34.0599 32.3946 34.6226 31.3307 34.8422 30.174C36.3864 22.0582 36.3864 13.9418 34.8422 5.826C34.6226 4.66927 34.0599 3.60542 33.2273 2.77287C32.3948 1.94033 31.3309 1.37763 30.1742 1.158C26.1616 0.390337 22.0856 0.00262201 18.0002 0C13.9148 0.00262201 9.83879 0.390337 5.82619 1.158Z" fill="#EA1B0A"/>
                </svg>
                <svg v-else width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76825 1.544C6.22594 1.83684 4.80747 2.5871 3.69741 3.69716C2.58735 4.80723 1.83709 6.22569 1.54425 7.768C-0.51475 18.589 -0.51475 29.411 1.54425 40.232C1.83709 41.7743 2.58735 43.1928 3.69741 44.3028C4.80747 45.4129 6.22594 46.1632 7.76825 46.456C13.1184 47.4794 18.5531 47.9964 24.0002 48C29.4474 47.9964 34.8821 47.4794 40.2323 46.456C41.7746 46.1632 43.193 45.4129 44.3031 44.3028C45.4132 43.1928 46.1634 41.7743 46.4563 40.232C48.5153 29.411 48.5153 18.589 46.4563 7.768C46.1634 6.22569 45.4132 4.80723 44.3031 3.69716C43.193 2.5871 41.7746 1.83684 40.2323 1.544C34.8821 0.520449 29.4474 0.00349601 24.0002 0C18.5531 0.00349601 13.1184 0.520449 7.76825 1.544Z" fill="#EA1B0A"/>
                </svg>
                <!-- eslint-enable max-len -->
            </span>
        </span>
    </PrimeButton>
    <PrimeButton v-else class="custom-icon-only-button" :icon="icon" type="button"/>
</template>

<script>

    import PrimeButton from "primevue/button";

    export default {
        name: "eon-icon-button",
        components: {PrimeButton},
        props: {
            icon: {
                type: String,
                default: null,
            },
            small: {
                type: Boolean,
                default: false,
            },
            medium: {
                type: Boolean,
                default: false,
            },
            withSvg: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/icon-button";
</style>
