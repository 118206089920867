<template>
    <div class="table-view">
        <div class="eon-search-criteria">
            <EonInputNumber width="220" label="Numer użytkownika" name="Numer" v-model="internalValue.id"
                            @update:modelValue="update"/>
            <EonSelectOneSystemRole width="220" itemLabel="name" label="Rola" :empty-value="true"
                                    v-model="internalValue.systemRole" @update:modelValue="update"/>
            <EonSelectOneDeletedOrCC width="220" label="Usunięty" v-model="internalValue.deleted"
                                     @update:modelValue="update" select-type="user"/>
            <EonInputText width="220" label="Imię" v-model="internalValue.firstName" @update:modelValue="update"/>
            <EonInputText width="220" label="Nazwisko" v-model="internalValue.lastName" @update:modelValue="update"/>
            <router-link :to="{name: 'userAddByAdmin'}">
                <EonButton extension="50" label="Dodaj użytkownika" icon="pi pi-plus"/>
            </router-link>
            <EonButton extension="50" label="Pobierz raport" icon="pi pi-download" @click="downloadReport"/>
            <EonButton extension="50" label="Wyniki ankiety" icon="pi pi-download" @click="downloadPollResults"/>
        </div>
    </div>
</template>

<script>
    import EonInputNumber from "@/components/form/EonInputNumber";
    import EonDropdown from "@/components/form/EonDropdown";
    import EonInputText from "@/components/form/EonInputText";
    import EonButton from "@/components/form/EonButton";
    import EonSelectOneSystemRole from "@/components/form/EonSelectOneSystemRole";
    import EonSelectOneDeletedOrCC from "@/components/form/EonSelectOneDeletedOrCC";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "UsersSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },
            downloadReport() {
                // eslint-disable-next-line max-len
                FileUtils.downloadFileUsingGET("Planowane wyłączenie.xlsx", "/api/planned-outage/report");
            },
            downloadPollResults() {
                // eslint-disable-next-line max-len
                FileUtils.downloadFileUsingGET("Ankieta.xlsx", "/api/poll/results");
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            EonSelectOneSystemRole,
            EonButton,
            EonInputText,
            EonDropdown,
            EonInputNumber,
            EonSelectOneDeletedOrCC,
        },
    };
</script>

<style lang="less" scoped>
@import "../../assets/less/eon-search-criteria.less";
</style>
