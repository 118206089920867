<template>
    <Toast/>
    <h1>Przerwa serwisowa</h1>

    <div class="maintenance-form-container">
        <VeeForm v-if="loaded" @submit="submit" @keydown.enter="$event.preventDefault()" class="form">
            <div class="field">
                <EonTimePicker width="220" label="Czas rozpoczęcia" name="picker" v-model="maintenanceData.start" />
            </div>
            <div class="field">
                <EonTimePicker width="220" label="Czas zakończenia" name="picker" v-model="maintenanceData.end" />
            </div>
            <div class="field">
                <EonCheckbox v-model="maintenanceData.show" label="Pokaż publiczne ostrzeżenie"/>
            </div>
            <div class="buttons">
                <EonButton label="Zapisz" type="submit" :disabled="isDisabled()"
                           @click="submit" />
                <EonOutlinedButton label="Anuluj" type="button" @click="goBack" />
            </div>
        </VeeForm>
    </div>
</template>

<script>

    import EonTimePicker from "@/components/form/EonTimePicker";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import Toast from "primevue/toast";
    import {
        editConfigsUsingPOST as editConfigs,
        getConfigsUsingGET as getConfigs,
    } from "@/swagger/vue-api-client";
    import {maintenanceEndKey, maintenanceShowKey, maintenanceStartKey} from "@/constants";

    export default {
        name: "MaintenanceView",

        components: {
            EonOutlinedButton,
            EonButton,
            EonCheckbox,
            EonTimePicker,
            Toast,
        },

        props: {
            value: {},
        },

        beforeMount() {
            getConfigs().then((response) => {
                response.data.forEach((config) => {
                    if (config.key === maintenanceStartKey) {
                        this.maintenanceData.start = new Date(config.value);
                    } else if (config.key === maintenanceEndKey) {
                        this.maintenanceData.end = new Date(config.value);
                    } else if (config.key === maintenanceShowKey) {
                        this.maintenanceData.show = config.value === "true" || config.value === true;
                    }
                });
            });
        },

        data() {
            return {
                loaded: true,
                maintenanceData: {
                    start: null,
                    end: null,
                    show: false,
                },
            };
        },
        methods: {
            submit() {
                editConfigs({
                    configRequests: [{key: maintenanceStartKey, value: this.maintenanceData.start},
                                     {key: maintenanceEndKey, value: this.maintenanceData.end},
                                     {key: maintenanceShowKey, value: this.maintenanceData.show}],
                }).then(() => {
                    this.$toast.removeAllGroups();
                    this.$toast.add({
                        severity: "success",
                        summary: "Zapisano dane",
                        life: 2500,
                    });
                });
            },
            isDisabled() {
                return !(this.maintenanceData.end !== null && this.maintenanceData.start !== null);
            },
        },
    };
</script>

<style lang="less" scoped>
.maintenance-form-container {
    width: 100%;
    display: flex;
    gap: 2%;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
    .input-width-set {
        width: 510px;
        @media screen and (max-width: 1480px) {
            width: 80%;
        }
    }
    .form {
        width: 42%;
        .buttons {
            display: flex;
            justify-content: center;
            gap: 40px;
            margin-top: 80px;
        }
        .field {
            gap: 0;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
