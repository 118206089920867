<template>
    <div :class="computedClass">
        <div class="eon-search-criteria">
            <EonInputText width="220" label="Adres" name="address" v-model="internalValue.address"
                          @update:modelValue="update" />
            <EonTimePicker width="220" label="Czas rozpoczęcia" name="picker" v-model="internalValue.start"
                           @update:modelValue="update" />
            <EonSelectOneAcceptStatus width="220" itemLabel="name" label="Status akceptacji" :empty-value="true"
                                      v-model="internalValue.acceptStatus" @update:modelValue="update"/>
            <input type="file" ref="file" v-show="false" v-on:change="handleFile"
                   id="importExcel" accept=".xlsx, .xls" multiple />
            <label class="hidden" for="importExcel">Importuj plik Excel</label>
            <EonButton name="importExcel" @click="importFile" extension="80" label="Importuj plik Excel" />
            <router-link :to="{name: 'plannedOutageReportAddByMaster'}">
                <EonButton extension="80" label="Dodaj wyłączenie" icon="pi pi-plus" />
            </router-link>
            <EonButton extension="80" label="Usuń wybrane" icon="pi pi-trash" @click="deleteSelected" />
        </div>
    </div>
</template>

<script>
    import {
        importAddressListUsingPOST as importAddressList,
    } from "@/swagger/vue-api-client";
    import EonInputText from "@/components/form/EonInputText";
    import EonTimePicker from "@/components/form/EonTimePicker";
    import EonButton from "@/components/form/EonButton";
    import FileUpload from "primevue/fileupload";
    import EonSelectOneAcceptStatus from "@/components/form/EonSelectOneAcceptStatus";
    // import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "PlannedOutageReportMasterSearchCriteria",

        components: {
            EonSelectOneAcceptStatus,
            EonInputText,
            EonTimePicker,
            EonButton,
            FileUpload,
        },

        props: {
            modelValue: null,
            mapView: {
                default: false,
            },
        },

        emits: ["update:modelValue", "confirmDelete"],

        data() {
            return {
                addressList: [],
                // Role: SystemRole,
            };
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            deleteSelected() {
                this.$emit("confirmDelete");
            },

            importFile() {
                this.$refs.file.click();
            },

            async handleFile() {
                /* eslint-disable no-await-in-loop */
                for (let i = 0; i < this.$refs.file.files.length; i += 1) {
                    const formData = new FormData();
                    formData.append("file", this.$refs.file.files[i]);

                    await importAddressList({file: formData}).then((response) => {
                        this.addressList = this.addressList.concat(response.data);
                    });
                }
                /* eslint-enable no-await-in-loop */
                this.$router.push({name: "plannedOutageReportAddByMaster", query: [...this.addressList]});
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClass() {
                return this.mapView ? "map-view" : "table-view";
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../assets/less/eon-search-criteria.less";
</style>
