<template>
    <EonPollDialog class="cookies">
        <h2> {{ $t('message.poll.dialogTitle') }} </h2>
        <p class="poll-message"> {{ $t('message.poll.dialogSubtitle') }} </p>
    </EonPollDialog>
    <EonCookies class="cookies">
    </EonCookies>
    <Toast position="top-center" group="tc" @close="onClose">
    </Toast>
    <div class="map-layout-wrapper">
        <EonTabMenu :leftItems="menuLeftItems" />
        <router-view />
    </div>
</template>

<script>
    import EonTabMenu from "@/components/form/EonTabMenu";
    import EonPollDialog from "@/views/public/EonPollDialog";
    import Toast from "primevue/toast";
    import {getConfigsUsingGET as getConfigs} from "@/swagger/vue-api-client";
    import {maintenanceEndKey, maintenanceShowKey, maintenanceStartKey} from "@/constants";
    import EonCookies from "@/components/form/EonCookies";

    const dateFormatter = new Intl.DateTimeFormat(
        "pl",
        {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
    );

    export default {
        name: "LayoutMap",
        components: {
            EonCookies,
            EonTabMenu,
            EonPollDialog,
            Toast,
        },
        data() {
            return {
                myHeight: "",
                maintenanceToastVisible: false,
                maintenanceData: {
                    start: null,
                    end: null,
                    show: false,
                },
                menuLeftItems: [
                    {
                        label: "Mapa",
                        to: "/public",
                    },
                    {
                        label: "Planowane wyłączenia",
                        to: "/public/planned",
                    },
                    {
                        label: "O Portalu",
                        to: "/public/about",
                    },
                    {
                        label: "Najczęściej zadawane pytania",
                        to: "/public/faq",
                    },
                    {
                        label: "Polityka prywatności",
                        to: "/public/privacy-policy",
                    },
                    {
                        label: "Ankieta",
                        to: "/public/poll",
                    },
                ],
                cookiesPolicyUrl: process.env.VUE_APP_COOKIES_URL,
            };
        },
        mounted() {
            getConfigs().then((response) => {
                response.data.forEach((config) => {
                    if (config.key === maintenanceStartKey) {
                        this.maintenanceData.start = new Date(config.value);
                    } else if (config.key === maintenanceEndKey) {
                        this.maintenanceData.end = new Date(config.value);
                    } else if (config.key === maintenanceShowKey) {
                        this.maintenanceData.show = config.value === "true";
                    }
                });
                if (this.maintenanceData.show === true) {
                    this.showMaintenanceInfo();
                }
            });
        },
        methods: {
            showMaintenanceInfo() {
                if (!this.maintenanceToastVisible) {
                    const dateStart = dateFormatter.format(this.maintenanceData.start);
                    const dateEnd = dateFormatter.format(this.maintenanceData.end);

                    this.$toast.add(
                        {
                            // eslint-disable-next-line max-len
                            severity: "warn", summary: "Od " + dateStart + " do " + dateEnd + " planujemy prace serwisowe na Portalu planowanych wyłączeń i awarii. Serwis może być w tym czasie niedostępny. Przepraszamy za utrudnienia.", group: "tc",
                        },
                    );
                    this.maintenanceToastVisible = true;
                }
            },
            onClose() {
                this.maintenanceToastVisible = false;
            },
        },

    };
</script>

<style lang="less">
@import "../../components/assets/less/toast.less";
@import "../assets/less/view-with-tab-menu.less";
.map-layout-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.maintenance-banner {
    width: 100%;
    height: 150%;
}
</style>
