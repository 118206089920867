<template>
    <div :style="'width: ' + width + 'px;'">
        <EonSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue" item-label="label"
                      item-value="value" :label="label" v-if="loading" :width="width"/>
    </div>
</template>

<script>
    import {getUserRolesUsingGET as getUserRoles} from "@/swagger/vue-api-client";
    import EonSelectOne from "./EonSelectOne";

    export default {
        name: "EonSelectOneSystemRole",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            width: {
                type: String,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [],
                loading: false,
            };
        },

        beforeMount() {
            getUserRoles().then((response) => {
                this.items = response.data;
                this.loading = true;
            });
        },

        components: {EonSelectOne},
    };
</script>

<style scoped>
</style>
