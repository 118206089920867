<template>
    <div>
        <Toast />
        <DataTable ref="plannedOutageReportAcceptTable" class="outages-table" :value="plannedOutageReports"
                   :lazy="true" :paginator="true" :rowClass="rowClass"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort v-model:selection="selectedOutages"
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Elementy na stronie" responsiveLayout="scroll">
            <!-- <Column selectionMode="multiple" style="width: 40px" /> -->
            <Column header="Adres">
                <template #body="slotProps">
                    <ul v-if="slotProps.data.addresses.length <= 10" class="outage-address-list">
                        <li v-for="address in slotProps.data.addresses" :key="address.address">
                            <b>{{ address.nonFormattedAddress }}</b> - {{ address.street }}
                            {{ address.wesolaDistrict ? "(W)" : "" }}
                            {{ address.streetNumber }}{{ ", " + address.postcode }}
                        </li>
                    </ul>
                    <ul v-else class="outage-address-list">
                        <li v-for="index in 10" :key="index">
                            <b>{{ slotProps.data.addresses[index].nonFormattedAddress }}</b> -
                            {{ slotProps.data.addresses[index].street }}
                            {{ slotProps.data.addresses[index].wesolaDistrict ? "(W)" : "" }}
                            {{ slotProps.data.addresses[index].streetNumber }}
                            {{ ", " + slotProps.data.addresses[index].postcode }}
                        </li>
                        <b>...</b>
                    </ul>
                </template>
            </Column>
            <Column field="outageStart" header="Czas rozpoczęcia" :sortable="true" style="width: 140px;" />
            <Column field="outageEnd" header="Czas zakończenia" :sortable="true" style="width: 140px;" />
            <Column field="createdBy.fullName" header="Dodane przez" :sortable="true" />
            <Column field="voltageLevel" header="Napięcie" :sortable="true" style="text-align: center;">
                <template #body="slotProps">
                    {{ slotProps.data.voltageLevel === "LV" ? "nN" : "" }}
                    {{ slotProps.data.voltageLevel === "MV" ? "SN" : "" }}
                    {{ slotProps.data.voltageLevel === "HV" ? "WN" : "" }}
                </template>
            </Column>
            <Column field="acceptStatus" header="Status akceptacji" :sortable="true" style="width: 160px;">
                <template #body="slotProps">
                    <span v-if="slotProps.data.acceptStatus === 'ACCEPTED'">
                        Zaakceptowane
                    </span>
                    <span v-else-if="slotProps.data.acceptStatus === 'REJECTED'">
                        Odrzucone
                    </span>
                    <span v-else>
                        Do akceptacji
                    </span>
                </template>
            </Column>
            <Column style="width: 60px">
                <template #body="slotProps">
                    <div class="button-column">
                        <router-link :to="{name: 'plannedOutageReportCallCenterShow',
                                           params: {'id': slotProps.data.id}}">
                            <PrimeButton class="outages-button outages-button-show" />
                        </router-link>
                    </div>
                </template>
            </Column>
            <template #loading>
                Ładowanie planowanych wyłączeń. Proszę czekać.
            </template>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import PrimeButton from "primevue/button";
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonDeleteOrRefreshPopup from "@/components/form/EonDeleteOrRefreshPopup";
    import Toast from "primevue/toast";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {TimeUtils} from "@/utils/TimeUtils";
    import {
        searchPlannedOutageReportUsingPOST as searchPlannedOutageReport,
        searchPlannedOutageReportCountUsingPOST as searchPlannedOutageReportCount,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PlannedOutageReportCallCenterTable",

        components: {
            DataTable,
            Column,
            EonCheckbox,
            PrimeButton,
            PrimeDialog,
            EonButton,
            EonOutlinedButton,
            EonDeleteOrRefreshPopup,
            Toast,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                plannedOutageReports: [],
                showDialog: false,
                dialogStatus: "",
                selectedOutages: null,
                dialogData: {
                    outageStart: "",
                    outageEnd: "",
                    createdBy: "",
                },
                plannedOutageIndex: null,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchPlannedOutageReport({searchCriteria: TimeUtils.adjustRequestBody(this.searchCriteria)})
                    .then((response) => {
                        this.plannedOutageReports = response.data;
                        this.plannedOutageReports.forEach((report) => {
                            report.outageStart = new Date(report.outageStart)
                                .toLocaleString("pl-PL", TimeUtils.options);
                            report.outageEnd = new Date(report.outageEnd)
                                .toLocaleString("pl-PL", TimeUtils.options);
                        });
                        this.loading = false;
                    });

                SearchCriteria.updateUrl(this);
            },

            rowClass(rowData) {
                if (rowData.deleted) return "deleted-row";
                if (rowData.acceptStatus === "TO_ACCEPT") return "accepted-row";
                return null;
            },

            updateTotalRecords() {
                searchPlannedOutageReportCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../../components/assets/less/datatable.less";
@import "../../assets/less/eon-table.less";
.button-column {
    display: flex;
    justify-content: center;
    gap: 4px;
}
.accept-button {
    background-color: transparent;
    border: none;
}
.outages-button {
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    &-show {
        background-image: url("@/assets/show.svg");
    }
    &-edit {
        background-image: url("@/assets/edit_no_line.svg");
    }
    &-trash {
        background-image: url("@/assets/trash_bin.svg");
    }
    &-print {
        background-image: url("@/assets/printer.svg");
    }
}
.outage-address-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.button-container {
    display: flex;
    // justify-content: center;
    gap: 12px;
}
.accept-button-tick, .accept-button-cross {
    width: 32px;
    height: 32px;
    background-color: transparent;
}
</style>

<style lang="less">
@import "../../../assets/theme/variable.less";
.accepted-row {
    background-color: #E1EDED !important;
}
.accept-dialog {
    background-color: @color-white;
    width: 960px;
    height: 400px;
    border-radius: 16px;
    position: relative;
    .p-dialog-header {
        background-color: @color-grey-light;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        height: 48px;
        .p-dialog-title {
            color: @tint-grey-dark-01;
            font-weight: 400;
            padding-left: 1rem;
        }
        &-icons {
            padding-right: 1rem;
        }
    }
    .p-dialog-content {
        display: flex;
        justify-content: center;
        p {
            text-align: center;
            &.accept-dialog-question {
                font-size: 36px;
                margin-bottom: 0;
            }
            &.accept-dialog-description {
                font-size: 18px;
                margin-top: 12px;
                margin-bottom: 48px;
            }
        }
        .accept-dialog-buttons {
            display: flex;
            justify-content: center;
            gap: 80px;
        }
    }
    &-info {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        color: @color-red;
    }
}
</style>
