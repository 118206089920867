<template>
    <div>
        <EonButton :extension="extension" label="Dodaj obszary" icon="pi pi-plus" @click="open"/>
        <PrimeDialog class="add-area-dialog" :id="name" v-model:visible="display" :draggable="false"
                     :modal="true" :style="'width: ' + dialogWidth + 'px;'">
            <slot>
                <h2>Dodaj obszary</h2>
                <VeeForm class="dialog-input" @submit="fileUpload">
                    <input type="file" id="file" ref="file" @change="onFileUpload" hidden/>
                    <label for="file" />
                    <EonButton icon="pi pi-plus" label="Wybierz plik geoJSON" class="turquoise-button"
                               @click="chooseFile" color="turquoise" extension="50"/>
                    <div class="filename" v-if="fileChosen"> Wybrano plik: {{ this.filename }} </div>
                    <div v-if="badFileType" class="file-error">
                        <i class="pi pi-exclamation-triangle" />
                        Akceptowane są wyłącznie pliki geoJSON
                    </div>
                    <Toolbar class="buttons">
                        <template #start>
                            <EonButton label="Wgraj wybrany plik" :disabled="!fileChosen || badFileType"
                                       icon="pi pi-save" extension="50" class="turquoise-button" type="submit"/>
                        </template>
                        <template #end>
                            <EonOutlinedButton label="Anuluj" class="turquoise-button" color="turquoise"
                                               extension="30" @click="close"/>
                        </template>
                    </Toolbar>
                </VeeForm>
            </slot>
        </PrimeDialog>
    </div>
</template>
<script>

    import {
        importAllAreasUsingPOST_TYPE as importMethod,
        importAllAreasUsingPOSTURL as importURL,
    } from "@/swagger/vue-api-client";
    import {Form as VeeForm} from "vee-validate";
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import EonIconButton from "@/components/form/EonIconButton";
    import Toolbar from "primevue/toolbar";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonMultiselect from "@/components/form/EonMultiselect";
    import axios from "axios";

    export default {
        name: "EonAddAreasPopup",
        props: {
            type: {
                type: String,
            },
            name: {
                type: String,
            },
            dialogWidth: {
                type: String,
            },
            reload: {
                type: Function,
            },
            extension: {
                type: String,
                default: "0",
            },
        },

        data() {
            return {
                searchCriteria: {
                    systemRole: "DISPATCHER",
                },
                area: {
                    name: null,
                    areaId: null,
                    subAreas: [],
                },
                dispatchers: [],
                display: false,
                fileChosen: false,
                badFileType: false,
                filename: null,
            };
        },

        methods: {
            chooseFile() {
                this.$refs.file.click();
            },
            onFileUpload() {
                const file = this.$refs.file.files[0];
                this.filename = file.name;
                this.fileChosen = true;
                const fileNameSplitted = file.name.split(".");

                if (fileNameSplitted[fileNameSplitted.length - 1].toUpperCase() !== "GEOJSON") {
                    this.badFileType = true;
                } else {
                    this.badFileType = false;
                    this.formData = new FormData();
                    this.formData.append("file", file);
                }
            },
            async fileUpload() {
                await axios({
                    url: importURL(),
                    data: this.formData,
                    method: importMethod(),
                    headers: {
                        accept: "application/json",
                        "content-type": "multipart/form-data",
                    },
                });
                this.close();
                this.reload();
            },
            open() {
                this.display = true;
            },
            close() {
                this.display = false;
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
        },

        components: {
            EonOutlinedButton,
            EonIconButton,
            PrimeDialog,
            EonButton,
            Toolbar,
            EonMultiselect,
            VeeForm,
        },
    };
</script>

<style lang="less" >
@import "../../assets/theme/variable.less";
@import "../assets/less/dialog.less";

.p-toolbar.buttons {
    justify-content: center;
    gap: 1em;
}
.dialog-input {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.add-area-dialog {
    h2 {
        text-align: left;
        color: @color-turquoise;
    }
}

.add-area-dialog.p-dialog {
    .p-dialog-header {
        background: @color-white;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        justify-content: end;
        padding-top: 1em;
        padding-right: 1em;
        .p-dialog-header-icon {
            width: 2.5rem;
            height: 2.5rem;
            color: @color-grey-middle;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin-right: 0.5rem;
            &:enabled {
                &:hover {
                    color: @color-grey-middle-active;
                    border-color: @color-transparent;
                }
            }
            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.add-area-dialog.p-dialog {
    width: 40%;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0  rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid @color-turquoise;

    .p-dialog-content {
        background: @color-white;
        color: @color-black;
        padding: 0 3rem 1.5rem 3rem;
    }
    .p-dialog-footer {
        border-top: 0 none;
        background: @color-white;
        color: @color-black;
        padding: 1rem 1.5rem;
        text-align: right;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }
    }
}
.filename {
    color: @tint-grey-dark-01;
}
.file-error {
    color: @tint-bordeaux-00;
}
</style>
