<template>
    <div class="view">
        <h1>Planowane wyłączenie - podgląd</h1>
        <div class="outage-show-container">
            <div class="outage-data">
                <div class="show-outage-row">
                    <span class="show-outage-header">Czas rozpoczęcia:</span>
                    <span class="show-outage-data">{{ report.outageStart }}</span>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Czas zakończenia:</span>
                    <span class="show-outage-data">{{ report.outageEnd }}</span>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Lista adresów:</span>
                    <ul class="show-outage-data">
                        <li v-for="address in report.addresses" :key="address.address">
                            <b>{{ address.nonFormattedAddress }}</b> - {{ address.street }}
                            {{ address.wesolaDistrict ? "(W)" : "" }}
                            {{ address.streetNumber }}{{ ", " + address.postcode }}
                        </li>
                    </ul>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Prace do wykonania:</span>
                    <span class="show-outage-data">{{ report.comment }}</span>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Napięcie:</span>
                    <span class="show-outage-data">
                        {{ report.voltageLevel === "LV" ? "nN" : "" }}
                        {{ report.voltageLevel === "MV" ? "SN" : "" }}
                        {{ report.voltageLevel === "HV" ? "WN" : "" }}
                    </span>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Data ogłoszenia:</span>
                    <span class="show-outage-data">{{ report.outageAnnouncement }}</span>
                </div>
                <div class="show-outage-row">
                    <span class="show-outage-header">Nr SAP/CallNet</span>
                    <span class="show-outage-data">{{ report.taskNumber }}</span>
                </div>
                <div class="buttons">
                    <router-link v-if="systemRole.isDispatcher()"
                                 :to="{name: 'plannedOutageReportEditByMaster'}">
                        <EonButton label="Edytuj" type="button" />
                    </router-link>
                    <EonOutlinedButton label="Powrót" type="button" @click="goBack" />
                </div>
            </div>
            <div class="map">
                <EonMapShowArea @emitApi="takeApi" :coords="coords" :coordsArr="coordsArr" />
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonMapShowArea from "@/components/EonMapShowArea";
    import {
        getPlannedOutageReportByIdUsingGET as getReport,
    } from "@/swagger/vue-api-client";
    import {TimeUtils} from "@/utils/TimeUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "PlannedOutageReportMasterShowView",

        components: {
            EonButton,
            EonOutlinedButton,
            DataTable,
            Column,
            EonMapShowArea,
        },

        data() {
            return {
                report: {},
                api: null,
                coords: null,
                coordsArr: [],
                systemRole: SystemRole,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getReport({id: this.$route.params.id}).then((response) => {
                    this.report = response.data;
                    const helpCoorArr = [];
                    this.report.addresses.forEach((address) => {
                        helpCoorArr.push(address.coordinates);
                    });
                    this.coordsArr = helpCoorArr;
                    if (response.data.outageStart) {
                        this.report.outageStart = new Date(response.data.outageStart)
                            .toLocaleString("pl-PL", TimeUtils.options);
                    }
                    if (response.data.outageEnd) {
                        this.report.outageEnd = new Date(response.data.outageEnd)
                            .toLocaleString("pl-PL", TimeUtils.options);
                    }
                    if (response.data.outageAnnouncement) {
                        this.report.outageAnnouncement = new Date(response.data.outageAnnouncement)
                            .toLocaleString("pl-PL", TimeUtils.announcementOptions);
                    }
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        mounted() {
            this.coorFromAddress();
        },

        methods: {
            coorFromAddress(googleMapsApi) {
                if (googleMapsApi) {
                    this.geocoder = new googleMapsApi.Geocoder();
                    if (this.report.addresses) {
                        for (let i = 0; i < this.report.addresses.length; i += 1) {
                            this.geocoder.geocode({
                                address: this.report.addresses[i].nonFormattedAddress,
                            }, (results) => {
                                this.coordinates = {
                                    lng: results[0].geometry.location.lng(),
                                    lat: results[0].geometry.location.lat(),
                                };
                                this.takeCoords(this.coordinates);
                            });
                        }
                    }
                }
            },
            takeApi(value) {
                this.api = value;
                this.coorFromAddress(value);
            },
            takeCoords(value) {
                this.coords = value;
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="less" scoped>
    .outage-show-container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 2%;
        margin-top: 80px;
        .outage-data {
            width: 50%;
        }
        ul.show-outage-data {
            height: 200px;
            max-width: 480px;
            padding-right: 16px;
            overflow-y: auto;
            overflow-x: hidden;
            &:hover::-webkit-scrollbar {
                width: 10px;
            }
        }
        .show-outage {
            &-row {
                display: flex;
                gap: 16px;
                margin-bottom: 30px;
            }
            &-header {
                display: block;
                width: 200px;
                text-align: end;
            }
            &-data {
                display: block;
                text-align: start;
                margin: 0;
                max-width: 500px;
                padding: 0;
                li {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .buttons {
            display: flex;
            justify-content: center;
            margin-left: 123px;
            gap: 40px;
        }
        .map {
            width: 40%;
            height: 700px;
        }
    }
</style>

<style lang="less">
    @import "../../assets/theme/variable.less";
    .show-outage-table {
        tr {
            th {
                display: none;
            }
            td {
                display: flex;
                width: 100% !important;
                align-items: flex-start;
                gap: 16px;
                .p-column-title {
                    display: block !important;
                }
            }
        }
    }
</style>
