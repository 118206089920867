<template>
    <GoogleMap id="map" ref="GMap" api-key="AIzaSyBzgXAazWmBUNOesptSkmRKhA-EVDLE8DY"
               style="width: 100%; height: 100%;"
               :scale-control="true" @idle="setupZoomControls"
               :center="center" :zoom="12" :streetViewControl="false" :fullscreenControl="false"
               :mapTypeControl="false" :styles="styles" :clickableIcons="false">
        <GPolygon v-for="area in areas" :options="area" :key="hashArea(area)"/>
        <MarkerCluster :options="userClusterOptions" v-if="showMarkersOnMap">
            <CustomMarker v-for="userReport in userReports" :options="userReport"
                          :key="userReport.id"
                          @click="renderInfoWindowOnClick(userReport)"
                          @mouseover="renderInfoWindowOnHover(userReport)"
                          @focus="renderInfoWindowOnHover(userReport)"
                          @mouseout="hideInfoWindowOnMouseOut()"
                          @focusout="hideInfoWindowOnMouseOut()">
                <img src="../assets/IconMarkerTurquoise.svg" class="marker" alt="marker"/>
            </CustomMarker>
        </MarkerCluster>
        <MarkerCluster :options="plannedClusterOptions" v-if="showMarkersOnMap">
            <CustomMarker v-for="outage in plannedOutages" :options="outage" :key="outage.id"
                          @click="renderAreaOutage(outage)"
                          @mouseover="renderInfoWindowOnHover(outage)"
                          @focus="renderInfoWindowOnHover(outage)"
                          @mouseout="hideInfoWindowOnMouseOut()"
                          @focusout="hideInfoWindowOnMouseOut()">
                <img src="../assets/IconMarkerRed.svg" class="marker" alt="marker" />
            </CustomMarker>
        </MarkerCluster>
        <MarkerCluster :options="confirmedClusterOptions" v-if="showMarkersOnMap">
            <CustomMarker v-for="outage in confirmedOutages" :options="outage" :key="outage.id"
                          @click="renderAreaOutage(outage)"
                          @mouseover="renderInfoWindowOnHover(outage)"
                          @focus="renderInfoWindowOnHover(outage)"
                          @mouseout="hideInfoWindowOnMouseOut()"
                          @focusout="hideInfoWindowOnMouseOut()">
                <img src="../assets/IconMarkerBordeaux.svg" class="marker" alt="marker" />
            </CustomMarker>
        </MarkerCluster>
        <InfoWindow ref="GMWindow" :options="{ pixelOffset: {height: -30, width: 0}, disableAutoPan: true }"
                    @closeclick="deactivateMarker" v-if="showMarkersOnMap"/>
        <GCircle ref="GMCircle" :options="circleOptions" v-if="showMarkersOnMap"/>
    </GoogleMap>
</template>

<script>
    import {
        searchPlannedOutageReportUsingPOST as searchPlannedOutages,
        getAllPublicPlannedOutageReportUsingGET as getPlannedOutages,
        getAreasUsingGET as getAreas,
    } from "@/swagger/vue-api-client";
    import {
        GoogleMap, CustomMarker, MarkerCluster, InfoWindow, Polygon as GPolygon, Circle as GCircle,
    } from "vue3-google-map";
    import {MapUtils} from "@/utils/MapUtils";
    import hash from "object-hash";
    import {TimeUtils} from "@/utils/TimeUtils";
    import svgPlus from "@/components/form/svg/plus.svg";
    import svgMinus from "@/components/form/svg/minus.svg";

    export default {
        name: "EonMap",

        props: {
            searchCriteria: null,
            showMarkersOnMap: {
                default: true,
            },
            showAreaOutlines: {
                default: true,
            },
            centerProp: {
                default: {lat: 52.232222, lng: 21.008333},
            },
        },

        data() {
            return {
                circleOptions: MapUtils.createCircleOptions(),
                userClusterOptions: {renderer: MapUtils.userReportsClusterRenderer},
                plannedClusterOptions: {renderer: MapUtils.plannedReportsClusterRenderer},
                confirmedClusterOptions: {renderer: MapUtils.confirmedReportsClusterRenderer},
                userReports: [],
                plannedOutages: [],
                confirmedOutages: [],
                markerActive: false,
                activeAreaId: null,
                allowCircleRender: true,
                areas: null,
                center: {lat: 52.232222, lng: 21.008333},
                styles: [
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9d9d9d",
                            },
                        ],
                    },
                    {
                        featureType: "landscape",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#f6f6f4",
                            },
                        ],
                    },
                    {
                        featureType: "poi",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#fdfdfd",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [
                            {
                                visibility: "simplified",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#cad2d3",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#7a7a7a",
                            },
                        ],
                    },
                ],
            };
        },
        async mounted() {
            await this.setupZoomControls();
        },

        async beforeMount() {
            this.center = this.centerProp;
            const {searchCriteria} = this;
            if (this.searchCriteria !== undefined && this.searchCriteria.duration) {
                const timestamp = new Date();
                timestamp.setHours(timestamp.getHours() - this.searchCriteria.duration);
                searchCriteria.start = timestamp;
            }
            this.search(searchCriteria);
            await getAreas().then((response) => {
                console.log(response);
                if (this.showAreaOutlines) {
                    this.areas = MapUtils.adjustAreaOutlines(response.data);
                } else {
                    this.areas = MapUtils.adjustAreas(response.data);
                }
            });
        },

        methods: {
            hashArea(area) {
                return hash(area);
            },
            toggleVisibility(areaId) {
                const area = this.areas.find((el) => el.id === areaId);
                area.visible = !area.visible;
            },
            reload() {
                this.hideInfoWindow();
                this.search(this.searchCriteria);
            },
            renderCircle(center) {
                this.$refs.GMCircle.circle.setCenter(center);
                this.$refs.GMCircle.circle.setVisible(true);
            },
            hideCircle() {
                this.$refs.GMCircle.circle.setVisible(false);
            },
            hideInfoWindow() {
                this.$refs.GMWindow.infoWindow.close(this.$refs.GMap.map);
                this.hideCircle();
            },

            clearMarkers() {
                this.userReports = [];
                this.plannedOutages = [];
                this.confirmedOutages = [];
            },

            search(criteria) {
                this.clearMarkers();
                if (criteria) {
                    const none = !criteria.userReports && !criteria.plannedOutages && !criteria.confirmedReports;
                    if (criteria.plannedOutages || none) {
                        searchPlannedOutages({searchCriteria: TimeUtils.adjustRequestBody(criteria)})
                            .then((response) => {
                                this.plannedOutages = MapUtils.adjustAreaOutageMarkers(response.data);
                            });
                    }
                } else {
                    getPlannedOutages()
                        .then((response) => {
                            this.plannedOutages = MapUtils.adjustAreaOutageMarkers(response.data);
                        });
                }
            },

            renderInfoWindow(marker) {
                this.$refs.GMWindow.infoWindow.setPosition(marker.position);
                this.$refs.GMWindow.infoWindow.setContent(MapUtils.createInfoWindowContent(marker, false));
                this.$refs.GMWindow.infoWindow.open(this.$refs.GMap.map);
            },
            renderInfoWindowOnHover(marker) {
                if (!this.markerActive) {
                    this.renderInfoWindow(marker);
                }
            },
            renderInfoWindowOnClick(marker) {
                this.deactivateMarker();
                this.markerActive = true;
                this.renderInfoWindow(marker);
                this.renderCircle(marker.position);
            },
            hideInfoWindowOnMouseOut() {
                if (!this.markerActive) {
                    this.$refs.GMWindow.infoWindow.close(this.$refs.GMap.map);
                }
            },
            renderAreaOutage(outage) {
                if (outage.outageCircle === "SMALL") {
                    this.$refs.GMCircle.circle.setRadius(this.smallCircleRadius);
                } else if (outage.outageCircle === "MEDIUM") {
                    this.$refs.GMCircle.circle.setRadius(this.mediumCircleRadius);
                }
                this.deactivateMarker();
                this.markerActive = true;
                this.renderCircle(outage.position);
                this.renderInfoWindow(outage);
            },
            updateCenter(coords) {
                this.$refs.GMap.map.panTo(coords);
                this.$refs.GMap.map.setZoom(18);
            },

            async setupZoomControls() {
                await setTimeout(() => {
                    Array.from(document.getElementsByClassName("gm-control-active")).forEach((button) => {
                        // Usunięcie tła.
                        button.parentElement.style.boxShadow = "";
                        button.parentElement.style.backgroundColor = "";

                        // Zmiana plusa/minusa na ten z figmy.
                        if (button.title === "Zoom in" || button.title === "Powiększ") {
                            Array.from(button.children).forEach((child) => {
                                child.src = svgPlus;
                            });
                        } else if (button.title === "Zoom out" || button.title === "Pomniejsz") {
                            Array.from(button.children).forEach((child) => {
                                child.src = svgMinus;
                            });
                        }
                        const iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                        const iconPath = document.createElementNS(
                            "http://www.w3.org/2000/svg",
                            "path",
                        );

                        if (button.getAttribute("aria-label") === "Zoom in"
                            || button.getAttribute("aria-label") === "Powiększ") {
                            button.style.setProperty("top", "-4px");
                        }

                        iconSvg.setAttribute("fill", "none");
                        iconSvg.setAttribute("viewBox", "0 0 48 48");
                        iconSvg.setAttribute("width", "40");
                        iconSvg.setAttribute("height", "40");
                        iconPath.setAttribute(
                            "d",
                            // eslint-disable-next-line max-len
                            "M7.76825 1.544C6.22594 1.83684 4.80747 2.5871 3.69741 3.69716C2.58735 4.80723 1.83709 6.22569 1.54425 7.768C-0.51475 18.589 -0.51475 29.411 1.54425 40.232C1.83709 41.7743 2.58735 43.1928 3.69741 44.3028C4.80747 45.4129 6.22594 46.1632 7.76825 46.456C13.1184 47.4794 18.5531 47.9964 24.0002 48C29.4474 47.9964 34.8821 47.4794 40.2323 46.456C41.7746 46.1632 43.193 45.4129 44.3031 44.3028C45.4132 43.1928 46.1634 41.7743 46.4563 40.232C48.5153 29.411 48.5153 18.589 46.4563 7.768C46.1634 6.22569 45.4132 4.80723 44.3031 3.69716C43.193 2.5871 41.7746 1.83684 40.2323 1.544C34.8821 0.520449 29.4474 0.00349601 24.0002 0C18.5531 0.00349601 13.1184 0.520449 7.76825 1.544Z",
                        );
                        iconPath.setAttribute("clip-rule", "evenodd");
                        iconPath.setAttribute("fill-rule", "evenodd");
                        iconPath.setAttribute("fill", "#EA1B0A");
                        iconSvg.appendChild(iconPath);
                        button.appendChild(iconSvg);
                    });
                }, 2000);
            },
            deactivateMarker() {
                this.hideCircle();
                this.markerActive = false;
                this.$refs.GMCircle.circle.setVisible(false);
                this.$refs.GMWindow.infoWindow.close(this.$refs.GMap.map);
                if (this.activeAreaId) {
                    this.hideArea(this.activeAreaId);
                    this.activeAreaId = null;
                }
            },
        },

        components: {
            GoogleMap,
            CustomMarker,
            MarkerCluster,
            InfoWindow,
            GPolygon,
            GCircle,
        },
    };
</script>
<style lang="less">
@import "../assets/theme/variable.less";
.marker {
    width: 25px;
}
.gm-style div {
    border: none !important;
}
.gm-style .gm-style-iw-c {
    border-radius: 4px;
    padding: 0;
}
.gm-style-iw-d {
    overflow: hidden !important;
    text-align: left;
}
.gm-ui-hover-effect {
    top: 3px !important;
    right: 5px !important;
}
// .info-window-content {
//     width: 601px;
//     &-header {
//         font-size: 20px;
//         font-weight: 300;
//         background-color: @color-grey-light;
//         text-align: left;
//         padding: 6px 20px 6px 15px;
//         position: relative;
//         &::after {
//             content: "";
//             background: url("../assets/IconThunderBolt.svg");
//             width: 90px;
//             height: 90px;
//             top: 90px;
//             right: 30px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             position: absolute;
//             display: inline-block;
//         }
//     }
//     &-header-cc {
//         font-size: 20px;
//         font-weight: 300;
//         background-color: @color-grey-light;
//         text-align: left;
//         padding: 6px 20px 6px 15px;
//         position: relative;
//         &::after {
//             content: "";
//             background: url("../assets/IconThunderBoltTurquoiseDark.svg");
//             width: 90px;
//             height: 90px;
//             top: 60px;
//             right: 30px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             position: absolute;
//             display: inline-block;
//         }
//     }
//     &-header-user {
//         font-size: 20px;
//         font-weight: 300;
//         background-color: @color-grey-light;
//         text-align: left;
//         padding: 6px 20px 6px 15px;
//         position: relative;
//         &::after {
//             content: "";
//             background: url("../assets/IconThunderBoltTurquoiseDark.svg");
//             width: 90px;
//             height: 90px;
//             top: 60px;
//             right: 30px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             position: absolute;
//             display: inline-block;
//         }
//     }
//     &-address, &-start-data, &-end-data, &-comment {
//         font-size: 16px;
//         padding: 0 25px;
//         font-weight: 900;
//         margin-block-start: 0.8em;
//         margin-block-end: 0;
//         color: @tint-grey-dark-01;
//         &-input {
//             padding: 0 25px;
//             font-size: 16px;
//             margin-block-start: 0.3em;
//             margin-block-end: 0.3em;
//             color: @tint-grey-dark-01;
//         }
//     }
//     &-address, &-comment {
//         margin-block-start: 1.7em;
//     }
//     &-remark {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         padding-left: 35px;
//         font-size: 13px;
//         color: @color-red;
//         margin-top: 5em;
//         margin-bottom: 1em;
//         font-weight: 700;
//         position: relative;
//     }
//     &-remark-cc {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 12px;
//         font-weight: 700;
//         padding-left: 60px;
//         padding-right: 100px;
//         margin-top: 5em;
//         margin-bottom: 3em;
//         color: @color-turquoise-dark;

//         &::after {
//             content: "";
//             background: url("../assets/IconMarkerInformationTurquoiseDark.svg");
//             width: 18px;
//             height: 18px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             position: absolute;
//             display: inline-block;
//             left: 25px;
//         }
//     }
//     &-remark-user {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 12px;
//         font-weight: 700;
//         padding-left: 60px;
//         padding-right: 100px;
//         margin-top: 5em;
//         margin-bottom: 3em;
//         color: @color-turquoise;

//         &::after {
//             content: "";
//             background: url("../assets/IconMarkerInformationTurquoise.svg");
//             width: 18px;
//             height: 18px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             position: absolute;
//             display: inline-block;
//             left: 25px;
//         }
//     }
//     &-actions {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 17px;
//         color: @color-turquoise-active;
//         margin-top: 2em;
//         margin-bottom: 2em;
//         position: relative;
//     }
//     &-actions-href {
//         font-weight: 800;
//     }
//     &-actions-icon {
//         margin-right: 0.5em;
//         margin-left: 1em;
//     }
// }

</style>
