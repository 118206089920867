<template>
    <div v-if="loaded" class="view">
        <div class="side">
            <div class="areas-list">
                <EonList ref="areaList" @area-selected="updateMap"/>
            </div>
            <div class="areas-buttons">
                <EonAddAreasPopup :reload="reloadAreas" extension="50"/>
            </div>
        </div>
        <div class="map-container">
            <EonMap class="map" :show-outlines="false"
                    ref="map" :show-markers-on-map="false"
                    :show-area-outlines="false"/>
        </div>
    </div>
</template>

<script>
    import EonList from "@/components/form/EonList";
    import Toast from "primevue/toast";
    import EonAddAreasPopup from "@/components/form/EonAddAreasPopup";
    import EonMap from "@/components/EonMap";
    import {MapUtils} from "@/utils/MapUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import {getAreasUsingGET as getAreas} from "@/swagger/vue-api-client";

    export default {
        data() {
            return {
                formData: "",
                loaded: false,
                areas: null,
                Role: SystemRole,
            };
        },

        methods: {
            reloadAreas() {
                this.$refs.areaList.searchItems();
                getAreas().then((response) => {
                    this.areas = MapUtils.adjustAreas(response.data);
                    // TODO pomyśleć, czy na pewno nie da się inaczej niż przeładowywać
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                });
            },
            updateMap(areaId) {
                this.$refs.map.toggleVisibility(areaId);
            },
        },
        beforeMount() {
            getAreas().then((response) => {
                this.areas = MapUtils.adjustAreas(response.data);
                this.loaded = true;
            });
        },

        computed: {
            canAddReportsOutsideAreasLabel() {
                return this.canAddReportsOutsideAreas ? "Można dodawać zgłoszenia poza obszarem"
                    : "Nie można dodawać zgłoszeń poza obszarem";
            },
        },

        components: {
            EonList,
            Toast,
            EonAddAreasPopup,
            EonMap,
        },
    };
</script>

<style lang="less" scoped>
@import url("@/views/assets/less/view-with-map.less");

.areas-list {
    width: 70%;
}

.side {
    justify-content: space-between;
    padding-top: 3em;
    padding-bottom: 2em;
}

.areas-buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 2.5em;
    align-items: center;
}

.map-container {
    height: 100%;
    width: 100%;
}

</style>
