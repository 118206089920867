<template>
    <div class="view">
        <Toast/>
        <h1>Dodaj użytkownika</h1>
        <VeeForm v-if="loaded" @submit="saveUser" v-slot="{ isSubmitting }" class="form">
            <div class="field">
                <p>Rola</p>
                <EonDropdown width="250" :items="role" itemLabel="name" itemValue="code"
                             placeholder="Rola" name="systemRole" v-model="user.systemRole" rules="required"/>
            </div>
            <div class="field">
                <p>Imię</p>
                <EonInputText width="250" label="Imię" name="firstName"
                              v-model="user.firstName" rules="required"/>
            </div>
            <div class="field">
                <p>Nazwisko</p>
                <EonInputText width="250" label="Nazwisko" name="lastName"
                              v-model="user.lastName" rules="required"/>
            </div>
            <div class="field">
                <p>E-mail</p>
                <EonInputText width="250" label="E-mail" name="email"
                              v-model="user.email" rules="required|email"/>
            </div>
            <div class="field">
                <p>Numer telefonu</p>
                <EonInputNumber width="250" label="Numer telefonu" name="phone" v-model="user.phone"/>
            </div>
            <div class="buttons">
                <EonButton label="Zapisz" type="submit" :disabled="isSubmitting"/>
                <EonOutlinedButton label="Anuluj" type="button" @click="goBack"/>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {
        createUserUsingPOST as createUser,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import {StatusCodes} from "@/utils/StatusCodes";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonInputText from "@/components/form/EonInputText";
    import EonDropdown from "@/components/form/EonDropdown";
    import EonInputNumber from "@/components/form/EonInputNumber";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "UserFormView",

        components: {
            EonOutlinedButton,
            Toolbar,
            CustomInputText,
            Toast,
            VeeForm,
            EonButton,
            EonInputText,
            EonDropdown,
            EonInputNumber,
            PrimeButton,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    systemRole: null,
                    area: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                },
                loaded: false,
                role: [
                    {name: "Mistrz prac planowych", code: "DISPATCHER"},
                    {name: "Administrator", code: "ADMIN"},
                    {name: "Akceptujący", code: "ACCEPTER"},
                    {name: "Call Center", code: "CALLCENTER"},
                ],
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            async saveUser() {
                await createUser({userRequest: this.user}).then(() => {
                    this.$toast.removeAllGroups();
                    this.$toast.add({
                        severity: "success",
                        summary: "Zapisano dane użytkownika",
                        life: 2500,
                    });
                })
                    .catch((error) => {
                        this.$toast.removeAllGroups();
                        if (error.response.status === StatusCodes.FORBIDDEN) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Nie masz wystarczających uprawnień",
                                life: 2500,
                            });
                        } else if (error.response.status === StatusCodes.BAD_REQUEST) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Użytkownik o podanym adresie email już istnieje",
                                life: 2500,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Wystąpił nieoczekiwany błąd",
                                life: 2500,
                            });
                        }
                    });
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 1000));
                this.$router.go(-1);
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../components/assets/less/toast.less";
@import "../assets/less/edit-view.less";

</style>
