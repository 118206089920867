<template>
    <div class="menu-field">
        <div class="menu-inner-field">
            <TabMenu :model="leftItems" />
            <div class="menu-right-side">
                <TabMenu class="menu-right-items" :model="rightItems">
                    <template #item="{item}">
                        <a class="menu-icon" @click="item.onClick" :href="item.to">
                            <span class="accessibility-text">{{item.label}}</span>
                            <i :class="item.icon" aria-hidden="true"/>
                        </a>
                    </template>
                </TabMenu>
                <a href="https://stoen.pl/"><img class="logo" src="../../assets/logoStoenRed.svg" alt="Logo" /></a>
            </div>
        </div>
    </div>
</template>
<script>
    import TabMenu from "primevue/tabmenu";

    export default {
        name: "EonTabMenu",
        components: {TabMenu},
        props: {
            leftItems: {
                type: Array,
            },
            rightItems: {
                type: Array,
            },
        },
    };

</script>
<style lang="less">
@import "../assets/less/tabmenu.less";
</style>
